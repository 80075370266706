/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql } from 'gatsby';

// UI local components
import { useTranslation } from 'react-i18next';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import TemplatePage from '../page-components/template-page';
// Assets
import BANNER_IMG from '../images/assistance-technique/banner.png';
import LUXOR_ASSISTANCE_IMG from '../images/assistance-technique/assistance.png';

// Styles
import '../page-styles/index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function AssistancePage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */

  // eslint-disable-next-line operator-linebreak
  const SITE_TITLE =
    'LUXOR TECHNOLOGY AND TRADE | Contacat Technical assistant ';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  //  localization
  const { t } = useTranslation();
  const { title, description1, description2, description3 } = t(
    'assistance-technique',
    { returnObjects: true },
  );

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <TemplatePage
        pageTitle={title}
        parag1={description1}
        parag2={description2}
        parag3={description3}
        bannerImg={BANNER_IMG}
        image={LUXOR_ASSISTANCE_IMG}
        imgAlt="luxor-assistance-technique"
        path="assistance-technique"
        titleBreadcrumbs={title}
      />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["assistance-technique", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AssistancePage;
