/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { graphql } from 'gatsby';

// PropTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible, Row, Col } from 'react-grid-system';

// UI local components
import BannerSection from '../page-solutions/banner';
import BeardCrumbs from '../breadCrumbs';

// Styles
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                            Presentation Page                               */
/* -------------------------------------------------------------------------- */

function TemplatePage({
  pageTitle,
  parag1,
  parag2,
  parag3,
  bannerImg,
  image,
  imgAlt,
  path,
  titleBreadcrumbs,
}) {
  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <BannerSection bannerImage={bannerImg}>
        <h1>{pageTitle}</h1>
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="" fluid>
          <BeardCrumbs path={path} titleBreadcrumbs={titleBreadcrumbs} />
        </Container>
      </Visible>
      <Container className="presentation-page fluid" fluid>
        <Container className="presentation-page-content fluid" fluid>
          <Row className="fluid">
            <Col
              className="fluid"
              xxl={7}
              xl={7}
              lg={7}
              md={12}
              sm={12}
              xs={12}
              fluid
            >
              <p className="description">
                {parag1}
                <br />
                {parag2}
                <br />
                {parag3}
              </p>
            </Col>
            <Col
              className="fluid"
              xxl={5}
              xl={5}
              lg={5}
              md={12}
              sm={12}
              xs={12}
            >
              <img className="luxor-tech-img pt-2" src={image} alt={imgAlt} />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

TemplatePage.defaultProps = {
  parag3: '',
};

TemplatePage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  parag1: PropTypes.string.isRequired,
  parag2: PropTypes.string.isRequired,
  parag3: PropTypes.string,
  bannerImg: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  titleBreadcrumbs: PropTypes.string.isRequired,
};

export default TemplatePage;
